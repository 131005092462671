import Layout from "../layout/components/Layout"
import NoResults from "../common/components/NoResults"
import React from "react"
import SEO from "../layout/components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <NoResults
      title="NOT FOUND"
      text="You just hit a route that doesn&#39;t exist... the sadness."
    />
  </Layout>
)

export default NotFoundPage
